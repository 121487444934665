<template>
  <!-- <a-modal :visible="true" width="1000px"> -->
  <a-form
    :wrapper-col="{ span: 14 }"
    class="myform"
    ref="formRef"
    :model="formState"
    :rules="rules"
  >
    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Tipe Gudang"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="type"
        >
          <a-select
            :options="[
              {
                label: 'Gudang Semen Fisik',
                value: 1,
              },
              {
                label: 'Gudang Semen Virtual',
                value: 2,
              },
              {
                label: 'Gudang Proyek',
                value: 3,
              },
              {
                label: 'Gudang DA (Direct Address)',
                value: 4,
              },
            ]"
            width="100%"
            v-model:value="type"
            :disabled="state.readOnly"
            @change="tipeChange"
            required
          />
        </a-form-item>

        <a-form-item
          label="Distributor"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="vendor_id"
        >
          <select-all-distributor
            style="width: 100%"
            show-search
            v-model:value="vendor_id"
            :join-options="vendors"
            :disabled="state.readOnly || state.isEdit"
            :mode="null"
            @change="distributorChange"
          ></select-all-distributor>
        </a-form-item>

        <a-form-item
          label="Nama Gudang"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="name"
        >
          <a-input
            width="100%"
            :addon-before="initName"
            v-model:value="name"
            :disabled="state.readOnly"
          />
        </a-form-item>

        <a-form-item
          label="Kode Gudang"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="code"
          :help="
            Array.isArray(errors)
              ? errors.find(i => i.field === 'code')
                ? errors.find(i => i.field === 'code').message
                : undefined
              : undefined
          "
          :validate-status="
            Array.isArray(errors)
              ? errors.find(i => i.field === 'code')
                ? 'error'
                : undefined
              : undefined
          "
        >
          <a-input width="100%" v-model:value="code" :disabled="state.readOnly || state.isEdit" />
        </a-form-item>
        <a-row class="form-row">
          <a-col :sm="{ span: 20, offset: 4 }">
            <a-row class="myform-vertical" layout="vertical" v-if="codes">
              <template v-for="(item, index) in codes" :key="index">
                <a-input
                  size="small"
                  type="hidden"
                  v-model:value="item.warehouse_id"
                  :disabled="state.readOnly || item.id !== null"
                />
                <a-row class="form-row">
                  <a-col :sm="4">
                    <a-form-item
                      label="Kode Ship To"
                      label-align="left"
                      :wrapper-col="{ span: 24 }"
                      :name="['codes', index, 'code']"
                      :rules="codeRules"
                      :help="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'code')
                            ? item.errors.find(i => i.field === 'code').message
                            : undefined
                          : undefined
                      "
                      :validate-status="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'code') !== null
                            ? 'error'
                            : undefined
                          : undefined
                      "
                    >
                      <a-input
                        size="small"
                        type="number"
                        v-model:value="item.code"
                        :disabled="
                          state.readOnly ||
                            item.id !== null ||
                            item.is_deleted ||
                            item.audit_trail_note
                        "
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="4">
                    <a-form-item
                      label="Tanggal Mulai"
                      label-align="left"
                      :wrapper-col="{ span: 24 }"
                    >
                      <a-date-picker
                        size="small"
                        type="text"
                        v-model:value="item.begin_date"
                        :disabled-date="c => disabledStartDated(c, item.end_date)"
                        :disabled="
                          state.readOnly ||
                            item.is_deleted ||
                            isEndDateExpired(item.end_date_temp) ||
                            item.audit_trail_note
                        "
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="4">
                    <a-form-item
                      label="Tanggal Selesai"
                      label-align="left"
                      :wrapper-col="{ span: 24 }"
                    >
                      <a-date-picker
                        size="small"
                        type="text"
                        v-model:value="item.end_date"
                        :disabled-date="c => disabledEndDated(item.begin_date, c)"
                        :disabled="
                          state.readOnly ||
                            item.is_deleted ||
                            isEndDateExpired(item.end_date_temp) ||
                            item.audit_trail_note
                        "
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="4">
                    <a-form-item
                      label="Brand"
                      label-align="left"
                      :wrapper-col="{ span: 24 }"
                      :name="['codes', index, 'brand_id']"
                      :rules="{
                        required: true,
                        message: 'brand tidak boleh kosong!',
                      }"
                      required
                      :help="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'brand_id')
                            ? item.errors.find(i => i.field === 'brand_id').message
                            : undefined
                          : undefined
                      "
                      :validate-status="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'brand_id') !== null
                            ? 'error'
                            : undefined
                          : undefined
                      "
                    >
                      <!-- <filter-brand
                        size="small"
                        :mode="null"
                        :wrapper-col="{ span: 24 }"
                        type="text"
                        v-model:value="item.brand_id"
                        :disabled="
                          state.readOnly ||
                            item.is_deleted ||
                            isEndDateExpired(item.end_date_temp) ||
                            item.audit_trail_note
                        "
                        style="width: 100%"
                      ></filter-brand> -->

                      <a-select
                        :mode="null"
                        v-model:value="item.brand_id"
                        placeholder="Pilih Brand"
                        style="width: 100%"
                        allow-clear
                        show-search
                        not-found-content="Tidak ditemukan"
                        option-filter-prop="label"
                        option-label-prop="label"
                        @search="onSearch"
                        @select="() => (findText = null)"
                        size="small"
                        :disabled="
                          state.readOnly ||
                            item.is_deleted ||
                            isEndDateExpired(item.end_date_temp) ||
                            item.audit_trail_note
                        "
                        :show-arrow="true"
                      >
                        <a-select-option v-for="d in dataBrand" :key="d.id" :label="d.name">
                          <!-- eslint-disable vue/no-v-html -->
                          <span v-html="highlight(d.name)"></span>
                          <!--eslint-enable-->
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :sm="2">
                    <a-form-item
                      v-if="resultRole === undefined"
                      label=" "
                      label-align="left"
                      :wrapper-col="{ span: 24 }"
                    >
                      <a-button
                        @click="removeRow(item)"
                        size="small"
                        type="danger"
                        :disabled="state.readOnly || item.is_deleted || item.audit_trail_note"
                        ><i class="fa fa-remove"></i
                      ></a-button>
                    </a-form-item>
                  </a-col>
                  <!-- <pre>{{ item }}</pre> -->
                </a-row>
              </template>
            </a-row>
            <!-- add function -->
            <template v-if="!state.readOnly">
              <a-form-item
                label-align="left"
                :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
                :colon="false"
              >
                <a-button type="primary" @click="addCode"
                  ><i class="fa fa-plus">&nbsp;</i> Tambah Kode Ship To</a-button
                >
              </a-form-item>
            </template>
          </a-col>
        </a-row>

        <a-form-item
          label="Kapasitas Gudang"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
          <a-input
            width="100%"
            addon-after="ZAK"
            type="number"
            @keypress="isNumber($event)"
            :min="0"
            v-model:value="kapasitas"
            :disabled="state.readOnly"
          />
        </a-form-item>

        <a-form-item
          label="Kapasitas Gudang Max"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          v-show="type === 4 ? false : true"
        >
          <a-input
            width="100%"
            addon-after="ZAK"
            type="number"
            @keypress="isNumber($event)"
            :min="0"
            v-model:value="kapasitas_max"
            :disabled="state.readOnly"
          />
        </a-form-item>

        <a-form-item
          label="Kapasitas Bongkar Muat"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
          <a-input
            addon-after="ZAK"
            width="100%"
            type="number"
            @keypress="isNumber($event)"
            :min="0"
            v-model:value="kapasitas_bongkarmuat"
            :disabled="state.readOnly"
          />
        </a-form-item>

        <a-form-item
          label="Pilih Metode Bongkar"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          v-show="type === 4 ? false : true"
        >
          <a-select
            v-model:value="metode_bongkar"
            :disabled="state.readOnly"
          >
            <a-select-option value="Pallet">Pallet</a-select-option>
            <a-select-option value="Non Pallet">Non Pallet</a-select-option>
            <a-select-option value="Non Pallet & Pallet">Non Pallet & Pallet</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="Alamat Gudang"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
          <a-input width="100%" v-model:value="address" :disabled="state.readOnly" />
        </a-form-item>
      </a-col>
    </a-row>
    <!-- wilayah_id -->
    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Kabupaten/Kota"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="wilayah_id"
        >
          <filter-wilayah-exc-prov
            v-model:value="wilayah_id"
            label="fullname"
            :disabled="state.readOnly"
            :join-options="
              state && state.record && state.record.wilayah_id
                ? [{ id: state.record.wilayah_id, fullname: state.record.wilayah }]
                : []
            "
          ></filter-wilayah-exc-prov>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Pilih Distrik Sidigi"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          name="district_sidigi"
        >
          <filter-distrik-sidigi
            show-search
            label-in-value
            v-model:value="district_sidigi"
            :disabled="state.readOnly"
            :mode="null"
          ></filter-distrik-sidigi>
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Kode Distrik SAP"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          has-feedback
          name="district_sap_code">
          <a-input
            placeholder="e.g 00"
            v-model:value="formState.district_sap_code"
            :disabled="state.readOnly"
            type="number"
            show-count
            :maxlength="18" />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Latitude"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          has-feedback
          name="lat"
          required
        >
          <a-input
            placeholder="e.g 00.0000"
            :class="!formState.lat ? '' : isValidLatitude != true ? `danger-input` : ``"
            v-model:value="formState.lat"
            show-count
            :maxlength="18"
            :disabled="state.readOnly"
            type="text"
          />
          <span
            v-if="!formState.lat ? '' : isValidLatitude != true"
            :class="
              !formState.lat ? '' : isValidLatitude != true ? `text-danger position-danger` : ``
            "
            >*Format anda salah</span
          >
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Longitude"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          has-feedback
          name="lng"
          required
        >
          <a-input
            placeholder="e.g 00.0000"
            v-model:value="formState.lng"
            :disabled="state.readOnly"
            type="text"
            :class="!formState.lng ? '' : isValidLongitude != true ? `danger-input` : ``"
            show-count
            :maxlength="18"
          />
          <span
            v-if="!formState.lng ? '' : isValidLongitude != true"
            :class="
              !formState.lng ? '' : isValidLongitude != true ? `text-danger position-danger` : ``
            "
            >*Format anda salah</span
          >
        </a-form-item>
      </a-col>
    </a-row>

    <!-- Rad Geofence & Parkir -->
    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Radius Geofence"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          has-feedback
          name="rad_geofence">
          <a-input
            width="100%"
            addon-after="METER"
            type="number"
            @keypress="isNumber($event)"
            :min="0"
            v-model:value="rad_geofence"
            :disabled="state.readOnly"/>
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-checkbox
          class="mr-2 mt-2"
          v-model:checked="parking_lot"
          :disabled="state.readOnly">Lahan Parkir</a-checkbox>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Sales Person"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="user"
        >
          <a-form-item :label-col="{ sm: { span: 6 } }" :wrapper-col="{ sm: { span: 24 - 6 } }">
            <a-col :sm="{ span: 24, offset: 6 }" :md="{ span: 12, offset: 0 }">
              <a-row class="myform-vertical" layout="vertical" v-if="users">
                <template v-for="(item, index) in users.filter(i => !i.is_deleted)" :key="index">
                  <a-input
                    size="small"
                    type="hidden"
                    v-model:value="item.vendor_id"
                    :disabled="state.readOnly || item.id !== null"
                  />
                  <a-row class="form-row">
                    <a-col :sm="12" :md="12">
                      <a-form-item
                        label="Role"
                        label-align="left"
                        :wrapper-col="{ sm: { span: 24 } }"
                        :name="['users', index, 'role_id']"
                      >
                        <filter-role
                          size="small"
                          :mode="null"
                          type="text"
                          style="width: 95%"
                          v-model:value="item.role_id"
                          placeholder="Pilih Role"
                          :disabled="state.readOnly || item.is_deleted || item.role_id !== null"
                        ></filter-role>
                      </a-form-item>
                    </a-col>
                    <a-col :sm="12" :md="12">
                      <a-form-item
                        label="User"
                        label-align="left"
                        :wrapper-col="{ span: 24 }"
                        :name="['users', index, 'user_id']"
                        :help="
                          Array.isArray(item.errors)
                            ? item.errors.find(i => i.field === 'user_id')
                              ? item.errors.find(i => i.field === 'user_id').message
                              : undefined
                            : undefined
                        "
                        :validate-status="
                          Array.isArray(item.errors)
                            ? item.errors.find(i => i.field === 'user_id') !== null
                              ? 'error'
                              : undefined
                            : undefined
                        "
                      >
                        <filter-sales
                          size="small"
                          :mode="null"
                          type="text"
                          v-model:value="item.user_id"
                          :disabled="state.readOnly || item.is_deleted"
                          @change="e => onChangeParent(e, index)"
                        ></filter-sales>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </template>
              </a-row>
            </a-col>
          </a-form-item>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row mt-5 justify-content-end">
      <a-col>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="state.readOnly"
          >Simpan</a-button
        >
      </a-col>
    </a-row>
    <!-- <pre>{{ state }}</pre> -->
    <a-modal
      v-if="itemDelete.showReason"
      v-model:visible="itemDelete.showReason"
      title="Reason"
      @ok="submitReason"
    >
      <p>Alasan menghapus kode gudang ini?</p>
      <a-input v-if="itemDelete.item" v-model:value="itemDelete.reason"></a-input>
      <a-alert v-if="itemDelete.error" :message="itemDelete.error" type="error" />
    </a-modal>
  </a-form>
  <!-- </a-modal> -->
</template>

<script>
import { onMounted, ref, reactive, inject, toRef, onBeforeMount, toRefs, computed } from 'vue'
import apiClient from '@/services/axios'
// import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'
import FilterWilayahExcProv from '@/components/filter/FilterWilayahExcProv'
// import SelectDistributor from '@/components/select/SelectDistributor'
import SelectAllDistributor from '@/components/select/SelectAllDistributor'
// import FilterBrand from '@/components/filter/FilterBrand'
import FilterRole from '@/components/filter/FilterRole'
import FilterSales from '@/components/filter/FilterSales'
import FilterDistrikSidigi from '@/components/filter/FilterDistrikSidigi'
import { Modal, message } from 'ant-design-vue'
import { useDisabledDate } from '@/composables/DisabledDate'
import moment from 'moment'
import { useStore } from 'vuex'

export default {
  components: {
    // FilterWilayahSemua,
    FilterWilayahExcProv,
    // SelectDistributor,
    SelectAllDistributor,
    // FilterBrand,
    FilterDistrikSidigi,
    FilterRole,
    FilterSales,
  },
  emits: ['finish', 'close'],
  setup(props, { attrs, slots, emit }) {
    const { disabledStartDated, disabledEndDated } = useDisabledDate()
    const segment = ref(1)
    const defWrapCol = reactive({ md: { span: 16, offset: 4 }, sm: { span: 24 } })
    const state = reactive(Object.assign({}, inject('formState').value))
    const store = useStore()
    const userData = computed(() => store.getters['user/user'])
    const resultRole = userData.value.roles.find(({ role }) => role === 'Admin OI')
    const formState = reactive({
      id: null, //
      code: null,
      name: null, //
      vendor_id: null, //
      distributor: null,
      distributor_code: null,
      address: null,
      wilayah_id: null, //
      district_sidigi_code: null,
      district_sidigi_name: null,
      kapasitas: null,
      kapasitas_max: null,
      kapasitas_bongkarmuat: null,
      metode_bongkar: '',
      type: null,
      lat: null,
      lng: null,
      rad_geofence: null,
      parking_lot: false,
      district_sap_code: null,
      codes: [],
      users: [],
      active: [],
      errors: [],
    })
    const readOnly = false
    const dataWilayah = ref([])
    const dataProvince = ref([])
    const formRef = ref()
    const initName = ref('')
    const initType = ref('')
    const initDistributor = ref('')
    const roles = ref([55403, 55407, 55414])
    const parents = ref([0, 0, 0, 0])
    const district_sidigi = ref('')
    const dataBrand = ref([])
    const findText = ref(null)
    const fetchDataBrand = q => {
      apiClient.get('/api/filter/brand-all?active=1', { params: q }).then(response => {
        dataBrand.value = response.data

        // melakukan pengecekan untuk
        // data brand yang sudah terdaftar

        if (q.length > 0) {
          codes.value.forEach(dataCodes => {
            if (dataCodes.brand_id != null) {
              // dataFilter = dataBrand.value.filter(brand => brand.id != dataCodes.brand_id)
              dataBrand.value.forEach((brand, index, object) => {
                if (brand.id === dataCodes.brand_id) {
                  object.splice(index, 1)
                }
              })
            }
          })
        } else {
          codes.value.forEach(dataCodes => {
            if (dataCodes.brand_id != null) {
              // dataFilter = dataBrand.value.filter(brand => brand.id != dataCodes.brand_id)
              dataBrand.value.forEach((brand, index, object) => {
                if (brand.id === dataCodes.brand_id) {
                  object.splice(index, 1)
                }
              })
            }
          })
        }
      })
    }

    onMounted(() => {
      fetchDataBrand()
    })

    const onSearch = value => {
      findText.value = value
      fetchDataBrand(value)
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    // return { data, valueState: useVModel(props, 'value'), findText, highlight, onSearch }

    if (state.isView === true || state.isEdit === true) {
      if (state.id)
        apiClient
          .get(`/api/warehouses/${state.id}`)
          .then(response => {
            const data = response.data

            Object.keys(formState).forEach(e => {
              formState[e] = data[e]
            })
            apiClient
              .get(`/api/warehouses/${state.id}/codes`)
              .then(({ data }) => {
                formState.codes = Object.assign(
                  [],
                  data.items.filter(i => !i.is_deleted),
                )
                codes.value.forEach(dataCodes => {
                  if (dataCodes.brand_id != null) {
                    // dataFilter = dataBrand.value.filter(brand => brand.id != dataCodes.brand_id)
                    dataBrand.value.forEach((brand, index, object) => {
                      if (brand.id === dataCodes.brand_id) {
                        object.splice(index, 1)
                      }
                    })
                  }
                })
                if (formState.codes === undefined) {
                  formState.codes = []
                }
                formState.codes.forEach(r => {
                  r.end_date_temp = r.end_date
                })
                var sales = []
                for (let i = 0; i < roles.value.length; i++) {
                  let params = { id: 0, role_id: roles.value[i] }
                  if (formState.users != undefined || formState.users != null) {
                    if (formState.users[i] != undefined || formState.users[i] != null) {
                      if (formState.users[i].id != undefined || formState.users[i].id != null) {
                        params.id = formState.users[i].id
                      }
                      if (
                        formState.users[i].vendor_id != undefined ||
                        formState.users[i].vendor_id != null
                      ) {
                        params.vendor_id = formState.users[i].vendor_id
                      }
                      if (
                        formState.users[i].user_id != undefined ||
                        formState.users[i].user_id != null
                      ) {
                        params.user_id = formState.users[i].user_id
                        parents.value[i + 1] = formState.users[i].user_id
                      }
                      if (
                        formState.users[i].begin_date != undefined ||
                        formState.users[i].begin_date != null
                      ) {
                        params.begin_date = formState.users[i].begin_date
                      }
                      if (
                        formState.users[i].end_date != undefined ||
                        formState.users[i].end_date != null
                      ) {
                        params.end_date = formState.users[i].end_date
                      }
                    }
                  }
                  sales.push(params)
                  console.log('formState.codes', formState.codes)
                }
                formState.users = sales
              })
              .catch(error => console.error(error))
            tipeChange()
            distributorChange(formState.vendor_id)
          })
          .catch(error => console.error(error))
    }
    // })

    const isNumber = evt => {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }

    // generate detail errors
    const detailError = (previousValue, currentValue, currentIndex, array) => {
      const regex = /\[(?<index>.+?)\]\[(?<field>.+?)\]/
      const match = currentValue.field.match(regex)
      if (!match) {
        return previousValue
      }
      const field = match.groups
      if (previousValue[field.index] === undefined) {
        previousValue[field.index] = []
      }
      previousValue[field.index].push({
        field: field.field,
        message: currentValue.message,
      })

      return previousValue
    }

    const isPosting = ref(false)
    const submit = async () => {
      try {
        formState.errors = undefined
        formState.codes.forEach(el => (el.errors = undefined))
        await formRef.value.validate()
      } catch (error) {
        console.log(error)
        return
      }
      // return
      if (state.isNew) {
        Modal.confirm({
          title: 'Konfirmasi Tambah Gudang',
          content: 'Apakah anda ingin melanjutkan menambahkan Gudang ?',

          onOk() {
            isPosting.value = true
            formState.active = 1
            // TODO: validasi
            const codes = formState.codes
            formState.codes = formState.codes
            const users = formState.users
            formState.users = formState.users.map(value => ({
              ...value,
              user_id: value.user_id ?? 0,
            }))
            formState.name = initName.value + formState.name
            formState.district_sidigi_code = district_sidigi?.value?.value
            formState.district_sidigi_name = district_sidigi?.value?.label?.replace(
              district_sidigi?.value?.value + ' - ',
              '',
            )

            if (validateLatitude.value != true && validateLongitude.value != true)
            apiClient
                .post('/api/warehouses', formState)
                .then(({ data }) => {
                  isPosting.value = false
                  formState.errors = []
                  emit('close')
                  emit('finish')

                  message.success('Berhasil menyimpan!')
                })
                .catch(e => {
                  message.error('Gagal menyimpan!')
                  console.error('post', e)
                  if (e.response.status === 422) {
                    formState.errors = e.response.data
                    const detailErrors = formState.errors.reduce(detailError, [])
                    formState.codes.forEach((el, idx) => {
                      console.log('errors', el, idx, detailErrors[idx])
                      if (detailErrors[idx]) {
                        el.errors = detailErrors[idx]
                        console.log({ el })
                        formState.codes[idx].errors = detailErrors[idx]
                      }
                    })
                    formState.users.forEach((el, idx) => {
                      console.log('errors', el, idx, detailErrors[idx])
                      if (detailErrors[idx]) {
                        el.errors = detailErrors[idx]
                        console.log({ el })
                        formState.users[idx].errors = detailErrors[idx]
                      }
                    })
                  }
                })
                .finally(() => (isPosting.value = false))
            isPosting.value = false
            if (validateLatitude.value === true && validateLongitude.value != true)
              Modal.warning({
                content: 'Pastikan Latitude dan Longitude terisi dengan benar!',
              })
            isPosting.value = false
            if (validateLatitude.value != true && validateLongitude.value === true)
              Modal.warning({
                content: 'Pastikan Latitude dan Longitude terisi dengan benar!',
              })
            if (validateLatitude.value === true && validateLongitude.value === true)
              apiClient
                .post('/api/warehouses', formState)
                .then(({ data }) => {
                  isPosting.value = false
                  formState.errors = []
                  emit('close')
                  emit('finish')

                  message.success('Berhasil menyimpan!')
                })
                .catch(e => {
                  message.error('Gagal menyimpan!')
                  console.error('post', e)
                  if (e.response.status === 422) {
                    formState.errors = e.response.data
                    const detailErrors = formState.errors.reduce(detailError, [])
                    formState.codes.forEach((el, idx) => {
                      console.log('errors', el, idx, detailErrors[idx])
                      if (detailErrors[idx]) {
                        el.errors = detailErrors[idx]
                        console.log({ el })
                        formState.codes[idx].errors = detailErrors[idx]
                      }
                    })
                    formState.users.forEach((el, idx) => {
                      console.log('errors', el, idx, detailErrors[idx])
                      if (detailErrors[idx]) {
                        el.errors = detailErrors[idx]
                        console.log({ el })
                        formState.users[idx].errors = detailErrors[idx]
                      }
                    })
                  }
                })
                .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (state.isEdit) {
        Modal.confirm({
          title: 'Konfirmasi Edit Gudang',
          content: 'Apakah anda ingin melanjutkan Edit Gudang ?',
          onOk() {
            isPosting.value = true
            formState.district_sidigi_code = district_sidigi?.value?.value
            formState.district_sidigi_name = district_sidigi?.value?.label?.replace(
              district_sidigi?.value?.value + ' - ',
              '',
            )
            // formState.name = initName.value + formState.name
            const form_data = new FormData()
            for (var key in formState) {
              form_data.append(key, formState[key])
              // if (key === "district_sidigi_code")
              //   formState[key].length <= 0 ? formState[key] = null : formState[key] = formState[key]
            }
            // TODO: validasi
            const codes = formState.codes
            formState.codes = formState.codes.filter(c => !c.audit_trail_note)
            const users = formState.users
            formState.users = formState.users.map(value => ({
              ...value,
              user_id: value.user_id ?? 0,
            }))

            if (validateLatitude.value != true && validateLongitude.value != true)
            apiClient
                .post('/api/warehouses/' + formState.id + '?_method=PUT', formState)
                .then(({ data }) => {
                  const promises = []
                  codes
                    .filter(c => c.audit_trail_note)
                    .forEach(c => {
                      promises.push(
                        apiClient.delete('/api/warehouses/' + formState.id + '/codes/' + c.id),
                      )
                    })

                  message.success('Berhasil mengupdate!')
                  emit('close')
                  emit('finish')
                  return Promise.allSettled(promises)
                })
                .catch(e => {
                  message.error('Gagal mengupdate!')
                  console.error('post', e)
                  if (e.response.status === 422) {
                    formState.errors = e.response.data
                    const detailErrors = formState.errors.reduce(detailError, [])
                    formState.codes.forEach((el, idx) => {
                      console.log('errors', el, idx, detailErrors[idx])
                      if (detailErrors[idx]) {
                        el.errors = detailErrors[idx]
                        console.log({ el })
                        formState.codes[idx].errors = detailErrors[idx]
                      }
                    })
                    formState.users.forEach((el, idx) => {
                      console.log('errors', el, idx, detailErrors[idx])
                      if (detailErrors[idx]) {
                        el.errors = detailErrors[idx]
                        console.log({ el })
                        formState.users[idx].errors = detailErrors[idx]
                      }
                    })
                  }
                })
                .finally(() => {
                  isPosting.value = false
                  formState.errors = []
                  // emit('close')
                  // emit('finish')
                })
            isPosting.value = false
            if (validateLatitude.value === true && validateLongitude.value != true)
              Modal.warning({
                content: 'Pastikan Latitude dan Longitude terisi dengan benar!',
              })
            isPosting.value = false
            if (validateLatitude.value != true && validateLongitude.value === true)
              Modal.warning({
                content: 'Pastikan Latitude dan Longitude terisi dengan benar!',
              })
            isPosting.value = false
            if (validateLatitude.value === true && validateLongitude.value === true)
              apiClient
                .post('/api/warehouses/' + formState.id + '?_method=PUT', formState)
                .then(({ data }) => {
                  const promises = []
                  codes
                    .filter(c => c.audit_trail_note)
                    .forEach(c => {
                      promises.push(
                        apiClient.delete('/api/warehouses/' + formState.id + '/codes/' + c.id),
                      )
                    })

                  message.success('Berhasil mengupdate!')
                  emit('close')
                  emit('finish')
                  return Promise.allSettled(promises)
                })
                .catch(e => {
                  message.error('Gagal mengupdate!')
                  console.error('post', e)
                  if (e.response.status === 422) {
                    formState.errors = e.response.data
                    const detailErrors = formState.errors.reduce(detailError, [])
                    formState.codes.forEach((el, idx) => {
                      console.log('errors', el, idx, detailErrors[idx])
                      if (detailErrors[idx]) {
                        el.errors = detailErrors[idx]
                        console.log({ el })
                        formState.codes[idx].errors = detailErrors[idx]
                      }
                    })
                    formState.users.forEach((el, idx) => {
                      console.log('errors', el, idx, detailErrors[idx])
                      if (detailErrors[idx]) {
                        el.errors = detailErrors[idx]
                        console.log({ el })
                        formState.users[idx].errors = detailErrors[idx]
                      }
                    })
                  }
                })
                .finally(() => {
                  isPosting.value = false
                  formState.errors = []
                  // emit('close')
                  // emit('finish')
                })
          },
          onCancel() {},
        })
      }
    }

    // validate lat long
    const latRegex = /^-?(?:90(?:\.0+)?|[0-8]?\d(?:\.\d+)?)$/
    const longRegex = /^-?(?:180(?:\.0+)?|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:\.\d+)?)$/

    const isValidLatitude = computed(() => {
      return latRegex.test(formState.lat)
    })
    const isValidLongitude = computed(() => {
      return longRegex.test(formState.lng)
    })

    const validateLatitude = isValidLatitude
    const validateLongitude = isValidLongitude

    // warhoouse codes
    const warehouseCode = ref({
      id: null,
      brand_id: null,
      warehouse_id: null,
      code: null,
      begin_date: null,
      end_date: null,
    })

    const codes = toRef(formState, 'codes')

    const users = toRef(formState, 'users')

    onBeforeMount(() => {
      if (formState.codes === undefined || formState.codes === null) {
        formState.codes = []
      }
      var sales = []
      for (let i = 0; i < roles.value.length; i++) {
        let params = { id: 0, role_id: roles.value[i] }
        if (formState.users != undefined || formState.users != null) {
          if (formState.users[i] != undefined || formState.users[i] != null) {
            if (formState.users[i].id != undefined || formState.users[i].id != null) {
              params.id = formState.users[i].id
            }
            if (formState.users[i].vendor_id != undefined || formState.users[i].vendor_id != null) {
              params.vendor_id = formState.users[i].vendor_id
            }
            if (formState.users[i].user_id != undefined || formState.users[i].user_id != null) {
              params.user_id = formState.users[i].user_id
              parents.value[i + 1] = formState.users[i].user_id
            }
            if (
              formState.users[i].begin_date != undefined ||
              formState.users[i].begin_date != null
            ) {
              params.begin_date = formState.users[i].begin_date
            }
            if (formState.users[i].end_date != undefined || formState.users[i].end_date != null) {
              params.end_date = formState.users[i].end_date
            }
          }
        }
        sales.push(params)
      }
      formState.users = sales
      if (
        state?.record?.district_sidigi_code != undefined ||
        state?.record?.district_sidigi_code != null
      ) {
        district_sidigi.value = { value: state.record.district_sidigi_code }
      }
    })

    const addCode = () => {
      if (codes.value.filter(v => v.code === null).length > 0) {
        message.warning('Pastikan kode referensi terisi!')
        return
      }
      isAddCode.value = false
      const m = Object.assign({}, warehouseCode.value)
      // m.id = 1
      codes.value.push(m)

      codes.value.forEach(dataCodes => {
        if (dataCodes.brand_id != null) {
          // dataFilter = dataBrand.value.filter(brand => brand.id != dataCodes.brand_id)
          dataBrand.value.forEach((brand, index, object) => {
            if (brand.id === dataCodes.brand_id) {
              object.splice(index, 1)
            }
          })
        }
      })
    }

    const removeRow = item => {
      const index = codes.value.indexOf(item)
      if (item.id === null) codes.value.splice(index, 1)
      else {
        itemDelete.item = item
        itemDelete.showReason = true
        // item.is_deleted = true
      }
    }

    const isAddCode = ref(true)

    const saveCode = () => {
      isAddCode.value = true
      resetCode()
    }

    const batalCode = () => {
      isAddCode.value = true
    }

    const resetCode = () => {
      warehouseCode.value = Object.assign(
        {},
        {
          id: null,
          brand_id: null,
          warehouse_id: null,
          code: null,
          begin_date: null,
          end_date: null,
        },
      )
    }

    const rules = {
      vendor_id: {
        required: true,
        message: 'Distributor tidak boleh kosong!',
      },
      name: {
        required: true,
        message: 'Masukan Nama Gudang',
      },
      code: {
        required: true,
        message: 'Masukan Kode Gudang',
      },
      wilayah_id: {
        required: true,
        message: 'Kabupaten/Kota belum dipilih!',
      },
      type: {
        required: true,
        message: 'Tipe gudang belum dipilih!',
      },
      lat: {
        required: true,
        message: 'Latitude tidak boleh kosong!',
      },
      lng: {
        required: true,
        message: 'Longitude tidak boleh kosong!',
      },
      rad_geofence: {
        required: true,
        message: 'Radius geofence tidak boleh kosong!',
      },
    }

    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0,
    )

    const isEndDateExpired = end_date_temp => {
      return typeof end_date_temp === 'string'
        ? new Date(end_date_temp).getTime() < today.getTime()
        : false
    }

    const itemDelete = reactive({
      showReason: false,
      item: null,
      reason: '',
      error: null,
    })

    const submitReason = () => {
      if (itemDelete.reason === null || itemDelete.reason.trim() === '') {
        itemDelete.error = 'Alasan tidak boleh kosong!'
        return
      } else {
        itemDelete.error = null
      }

      itemDelete.item.audit_trail_note = itemDelete.reason
      itemDelete.item.end_date = new moment(today)
      // itemDelete.item.is_deleted = true
      itemDelete.showReason = false
      itemDelete.item = null
      itemDelete.reason = ''
    }

    const codeRules = [
      {
        required: true,
        message: 'kode tidak boleh kosong!',
      },
      {
        validator: async (rule, value) => {
          if (value !== null && value.trim() !== '') {
            const count = formState.codes.filter(i => !i.is_deleted && i.code === value).length
            if (count > 1) return Promise.reject('Duplicate code!')
            else return Promise.resolve()
          } else {
            return Promise.resolve()
          }
        },
      },
    ]

    const vendors = computed(() => {
      if (!state.record || !state.record.vendor_id) return []
      return [
        {
          id: state.record.vendor_id,
          name: `${state.record.distributor_code} - ${state.record.distributor}`,
        },
      ]
    })

    const tipeChange = value => {
      if (state.isNew) {
        initName.value = ''
        initType.value = ''
        if (formState.type == 1) {
          initType.value = 'GD.'
        }
        if (formState.type == 2) {
          initType.value = 'GV.'
        }
        if (formState.type == 3) {
          initType.value = 'GP.'
        }
        if (formState.type == 4) {
          initType.value = 'DA.'
        }
        initName.value = initType.value + initDistributor.value
      }
    }

    const distributorChange = value => {
      if (state.isNew) {
        apiClient
          .get('/api/filter/distributor', {
            params: { q: value },
          })
          .then(response => {
            let result = response.data
            var init = result[0].short_code
            initName.value = ''
            initDistributor.value = init ? init + '.' : ''
            initName.value = initType.value + initDistributor.value
          })
      }
    }

    const onChangeParent = (value, index) => {
      parents.value[index + 1] = value
    }

    return {
      longRegex,
      latRegex,
      isValidLatitude,
      isValidLongitude,
      validateLatitude,
      validateLongitude,
      segment,
      defWrapCol,
      ...toRefs(formState),
      dataProvince,
      dataWilayah,
      formState,
      state,
      submit,
      isPosting,
      isAddCode,
      saveCode,
      removeRow,
      batalCode,
      warehouseCode,
      addCode,
      disabledStartDated,
      disabledEndDated,
      formRef,
      initName,
      initType,
      initDistributor,
      tipeChange,
      distributorChange,
      rules,
      today,
      isEndDateExpired,
      itemDelete,
      submitReason,
      codeRules,
      vendors,
      onChangeParent,
      roles,
      parents,
      users,
      district_sidigi,
      isNumber,
      userData,
      resultRole,
      highlight,
      onSearch,
      dataBrand,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
.danger-input {
  border: 1px solid red;
}

.position-danger {
  position: relative;
  top: -10px;
}
</style>
